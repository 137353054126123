<template>
  <main>
    <div class="content_wrapper">

      <div class="form form-around">
        <Form
          :class="'form_step' + step"
          id="monform"
          :form_id="form_id"
          :server="server"
          v-on:results="getResult"
          v-on:step-up="checkStep"
          v-on:step-down="downStep"
          v-on:form_submit_loading="loading"
          @on_error="viewError"
          :hideError="hiderror"
        />


      <div v-if="step==1" class="captcha">
        This site is protected by reCAPTCHA and the Google<br />
        <a href="https://policies.google.com/privacy" target="_blank"
          >Privacy Policy</a
        >
        and
        <a href="https://policies.google.com/terms" target="_blank"
          >Terms of Service</a
        >
        apply.
      </div>
      <br v-if="step == 1" >
      

            <div v-if="step == 1" class="cnil cn2">Données personnelles : informations complètes <a href="https://oxoformdocs.fra1.digitaloceanspaces.com/604bba5ec9ff400015f11f9f/politique.pdf" target="_blank">ici</a>. GROHE, en tant que responsable de traitement, met en œuvre un traitement de données à caractère personnel ayant pour finalité l'organisation et la gestion d'une opération Promotionnelle..
Les données traitées dans l'intérêt légitime de GROHE sont indispensables  à ce traitement et sont  utilisées par les services concernés de GROHE et le cas échéant de ses sous-traitants. Les données sont conservées pendant une durée de 3 ans à compter de la fin de l'offre promotionnelle ou tant que vous n'avez pas retiré votre consentement. Vous bénéficiez, d'un droit d'interrogation, d'accès, de rectification, d'un droit à la portabilité, à la limitation du traitement et d'opposition. Vous pouvez adresser vos demandes à GROHE via l'adresse email : dataprotection_fr@grohe.com pour l'exercice de vos droits sur le traitement. Si vous estimez que vos droits ne sont pas respectés, vous pouvez adresser une réclamation à la CNIL dont les coordonnées figurent à l'adresse internet <a href="https://www.cnil.fr" target="_blank">https://www.cnil.fr</a>.</div>
            <br />&nbsp;
   
      </div>
      <br>


   </div>
  </main>
</template>
<script>
var consts = require("../config");


export default {
  name: "form",
  data: function() {
    return {
      step: 0,
      hiderror: true,
      form_id: consts.FORM_ID,
      server: consts.SERVER_URL,
    };
  },
  methods: {
    loading(value) {
      if (value === true) {
        this.$swal({
          title: "Envoi en cours",
          html: "Merci de bien vouloir patienter ...",
          timerProgressBar: true,
          onBeforeOpen: () => {
            this.$swal.showLoading();
          },
        });
      }
    },
    checkStep() {
      this.step = this.step+1;
    },
    downStep() {
      this.step = this.step-1;
    },
    getResult(result) {

      if (result._id) {
        console.log('----------------------------', result.randomNumericId)
        window.result = result.randomNumericId
        this.$swal.close();
        this.$router.push("/done");
      } else {
          this.$swal({
            confirmButtonText: "OK",
            confirmButtonColor: "#C94330",
            title: "Désolé, une erreur est survenue.",
            text: "",
          })
      }
    },
    viewError(err) {
      console.log(err);

      if(err[0] ==  'produit_achetes is required') {
        err[0] = 'Vous devez sélectionner le produit acheté' 
      }

      this.$swal({
        confirmButtonText: "OK",
        confirmButtonColor: "#C94330",
        title: "Désolé, une erreur est survenue.",
        text: err[0],
      })
    },
  }
};
</script>
